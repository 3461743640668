import Button from '@mui/material/Button';
import lesson from "../images/lesson.png"
import img1 from "../images/SearchQuery.png"
import { useEffect, useState } from 'react';

export function LandingPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleScroll = () => {
        window.scrollBy({ top: window.innerHeight, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isMobile) {
        return (
            <div className="landingPage">
                <div className="landingBody">
                    <div className='headLine'>
                        <h1> Learn the way that works best for <span className="youText">you</span>.</h1>
                        <h2> Slang helps you learn what you want, how you want! </h2>
                        <Button 
                            className="muibutton" 
                            variant="contained"
                            sx={{
                                width: '15vw',
                                height: '8vh',
                                backgroundColor: '#6184D8',
                                '@media (max-width:768px)': { // This is a common breakpoint for mobile devices
                                    width: '50vw', // Example mobile width
                                    height: '8vh', // Example mobile height
                                    fontSize: '63px' // Adjusted font size for mobile
                                    // You can add more mobile-specific styles here
                                }
                            }}
                            // onClick={handleScroll}
                            href="https://testflight.apple.com/join/CORi2KWL" // Link to the desired URL
                        >
                            Get Started
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="landingPage">
            <div className="landingBody">
                <div className='headLine'>
                    <h1> Learn the way that works best for <span className="youText">you</span>.</h1>
                    <h2> Slang helps you learn what you want, how you want! </h2>
                    <Button 
                        className="muibutton" 
                        variant="contained"
                        sx={{
                            width: '15vw',
                            height: '8vh',
                            backgroundColor: '#6184D8',
                            '@media (max-width:768px)': { // This is a common breakpoint for mobile devices
                                width: '50vw', // Example mobile width
                                height: '8vh', // Example mobile height
                                fontSize: '63px' // Adjusted font size for mobile
                                // You can add more mobile-specific styles here
                            }
                        }}
                        // onClick={handleScroll}
                        href="https://testflight.apple.com/join/CORi2KWL" // Link to the desired URL
                    >
                        Get Started
                    </Button>
                </div>
                <div className="landingBodyImage">
                    <img src={img1} alt="cutout" className="cutout"/>
                    <img src={lesson} alt="cutout" className="cutout2"/>
                </div>
            </div>
        </div>
    );
}
