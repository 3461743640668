import "../style/Page4.scss";
import img2 from "../images/no_bg_cutout.png"
import grammar from "../images/grammar.png"
import explanation from "../images/explanation.png"
import review_screen from "../images/review_screen.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas, faBookOpenReader, faMicrophone, faRocket} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";

export function Page4View() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isMobile) {
        return (
            <div className="page4Container">
                <div className="mobileContainer">
                    <div className="bot">
                        <h1>Exercises fine tuned for you!</h1>
                        <h2>Education should not be one size fits all. Every question, quiz, and exercise is made to help you learn the way that works best for you!</h2>
                    </div>
                    <img src={img2} alt="cutout"/>
                    <div className="top">
                        <h1>Powered by AI</h1>
                        <h2>Our lesson content is generated by a constantly evolving language model. </h2>
                    </div>
                    <div className="top">
                        <h1>Thousands of Lessons!</h1>
                        <h2>Spanning a wide variety of topics, from moving to a new country, to Viking war cries!</h2>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {/* <div className="page4Title">
                <h1>Placeholder</h1>
            </div> */}
            <div className="page4Container">
                <div className="page4IconAndText">
                    <img src={review_screen} alt="cutout"/>
                    <div className="textContainer">
                        {/* <FontAwesomeIcon icon={faEarthAmericas} className='containerIcon'/> */}
                        <h1>Thousands of Lessons!</h1>
                        <h2>Spanning a wide variety of topics, from moving to a new country, to Viking war cries!</h2>
                    </div>
                </div>
                <div className="page4IconAndTextMid">
                    <img src={explanation} alt="cutout"/>
                    <div className="textContainer">
                        {/* <FontAwesomeIcon icon={faBookOpenReader} className='containerIcon'/> */}
                        <h1>Exercises fine tuned for you!</h1>
                        <h2>Education should not be one size fits all. Every question, quiz, and exercise is made to help you learn the way that works best for you!</h2>
                    </div>            
                </div>
                <div className="page4IconAndText">
                    <img src={grammar} alt="cutout"/>
                    <div className="textContainer">
                        {/* <FontAwesomeIcon icon={faRocket} className='containerIcon'/> */}
                        <h1>Powered by AI</h1>
                        <h2>Our lesson content is generated by a constantly evolving language model. </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}