import { MarqueeIphone } from "./MarqueeIPhone";
import { MarqueeRow } from "./MarqueeScroller";

type MarqueeCardProps = {
    numberOfRows: number;
    numberOfColumns: number;
    playVideo: (videoId: string) => void;
    rowData: MarqueeRow[];
};

export function MarqueeCard(marqueeCardProps: MarqueeCardProps) {
    const { numberOfRows, numberOfColumns, rowData } = marqueeCardProps;

    return (
        <div className="marqueePhoneContainer">
            {Array.from({ length: numberOfRows }, (_, rowIndex) => (
                <div key={`row-${rowIndex}`} style={{ flexDirection: "row", display: "flex" }}>
                    {rowData.map((data, columnIndex) => (
                        <MarqueeIphone 
                            key={`row-${rowIndex}-col-${columnIndex}`} 
                            rowIdx={rowIndex + columnIndex} 
                            playVideo={marqueeCardProps.playVideo}
                            subject={data.subject}
                            language={data.language}
                            phrase={data.phrase}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}
