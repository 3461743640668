import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { MarqueeCard } from './MarqueeCard';

type MarqueeScrollerProps = {
    playVideo: (videoId: string) => void;
}

export type MarqueeRow = {
    subject: string;
    language: string;
    phrase: string;
}

const row1: MarqueeRow[] = [
    { subject: "Tropical Ecology", language: "Spanish", phrase: "Ecología Tropical" },
    { subject: "Korean Drama", language: "Korean", phrase: "한국 드라마" },
    { subject: "Making Sushi", language: "Japanese", phrase: "寿司作り" },
    { subject: "Impressionist Art", language: "France", phrase: "Art impressionniste" },
    { subject: "Classical Music", language: "German", phrase: "Klassische Musik" },
    { subject: "Italian Cuisine", language: "Italian", phrase: "Cucina Italiana" },
    { subject: "Yoga Exercises", language: "Hindi", phrase: "योग प्राणायाम" },
    { subject: "Traditional Medicine", language: "Mandarin", phrase: "传统医学" },
    { subject: "Football Tactics", language: "Portuguese", phrase: "Táticas de Futebol" },
    { subject: "Desert Poetry", language: "Arabic", phrase: "شعر الصحراء" },
    { subject: "Minoan Archaeology", language: "Greek", phrase: "Μινωική αρχαιολογία" },
    { subject: "Ukrainian Embroidery", language: "Ukranian", phrase: "Українська вишивка" },
    { subject: "Druidic Rituals", language: "Welsh", phrase: "seremonïau derwydd" },
];

const row2: MarqueeRow[] = [
    { subject: "Amazonian Myths", language: "Spanish", phrase: "mitos amazónicos" },
    { subject: "Learning Taekwondo", language: "Korean", phrase: "태권도 배우기" }, 
    { subject: "Samurai Philosophy", language: "Japanese", phrase: "侍哲学" },
    { subject: "French Wines", language: "France", phrase: "Vins français" },
    { subject: "Alpine Yodeling", language: "German", phrase: "Alpenjodeln" },
    { subject: "Renaissance Sculpture", language: "Italian", phrase: "scultura del Rinascimento" },
    { subject: "Vedic Astrology", language: "Hindi", phrase: "वैदिक ज्योतिष" },
    { subject: "Panda Conservation", language: "Mandarin", phrase: "熊猫保护" },
    { subject: "Ancient Navigation", language: "Portuguese", phrase: "navegação antiga" },
    { subject: "Ottoman Calligraphy", language: "Turkish", phrase: "Osmanlı hat sanatı" },
    { subject: "Viking Sagas", language: "Swedish", phrase: "Vikingasagor" },
    { subject: "Siberian Folktales", language: "Russian", phrase: "сибирский фольклор" },
    { subject: "Tulip Cultivation", language: "Dutch", phrase: "tulpenkwekerij" },
]

export function MarqueeScroller(props: MarqueeScrollerProps) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) {
        return (
            <div className="marqueeThingy">
                <Marquee 
                    className="marqueeContainer" 
                    autoFill={true} 
                    speed={20} 
                    direction={"right"}
                >
                    <MarqueeCard 
                        numberOfRows={1} 
                        numberOfColumns={30} 
                        playVideo={props.playVideo}
                        rowData={row1}
                    />
                </Marquee>
                <div className="middleMarqueeText">
                    <h1>
                        What do <span className='highlight'>you</span> want to learn about today?
                    </h1>
                    <h2>
                        Explore our rapidly growing library of lessons, with new content added every day!
                    </h2>
                </div>
                <Marquee 
                    className="marqueeContainer" 
                    autoFill={true} 
                    speed={20} 
                    direction={"left"}
                >
                    <MarqueeCard 
                        numberOfRows={1} 
                        numberOfColumns={30} 
                        playVideo={props.playVideo}
                        rowData={row2}
                    />
                </Marquee>
            </div>
        )
    }

    return (
        <div className="marqueeThingy">
            <Marquee 
                className="marqueeContainer" 
                autoFill={true} 
                speed={20} 
                direction={"right"}
            >
                <MarqueeCard 
                    numberOfRows={1} 
                    numberOfColumns={30} 
                    playVideo={props.playVideo}
                    rowData={row1}
                />
            </Marquee>
            <div className="middleMarqueeText">
                <h1>
                    What do <span className='highlight'>you</span> want to learn about today?
                </h1>
                <h2>
                    Explore our rapidly growing library of lessons, with new content added every day!
                </h2>
            </div>
            <Marquee 
                className="marqueeContainer" 
                autoFill={true} 
                speed={20} 
                direction={"left"}
            >
                <MarqueeCard 
                    numberOfRows={1} 
                    numberOfColumns={30} 
                    playVideo={props.playVideo}
                    rowData={row2}
                />
            </Marquee>
        </div>
    );
}
