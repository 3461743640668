import "../style/Page5.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { useEffect, useState } from "react";

const testFlight = "https://testflight.apple.com/join/CORi2KWL";
export function Page5View() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isMobile) {
        return (
            <div className="closingSection">
                <div className="closingText">
                    <h1>Join the beta!</h1>
                    <h2>Why not? It's free.</h2>
                </div>
                <a href={testFlight} target="_blank" rel="noreferrer" className="downloadLink">
                    <FontAwesomeIcon icon={faApple} className="appleLogo"/>
                    <div className="downloadText">
                        <h2> Try on </h2>
                        <h1> Testflight </h1>
                    </div>
                </a>
            </div>
        )
    }
    return (
        <div className="closingSection">
            <div className="closingText">
                <h1>Join the beta!</h1>
                <h2>Why not? It's free.</h2>
            </div>
            <a href={testFlight} target="_blank" rel="noreferrer" className="downloadLink">
                <FontAwesomeIcon icon={faApple} className="appleLogo"/>
                <div className="downloadText">
                    <h2> Try on </h2>
                    <h1> Testflight </h1>
                </div>
            </a>
        </div>
    );
}