import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export const names = [
    { subject: "Tropical Ecology", language: "Spanish", phrase: "Ecología Tropical" },
    { subject: "Korean Drama", language: "Korean", phrase: "한국 드라마" },
    { subject: "Making Sushi", language: "Japanese", phrase: "寿司作り" },
    { subject: "Impressionist Art", language: "France", phrase: "Art impressionniste" },
    { subject: "Classical Music", language: "German", phrase: "Klassische Musik" },
    { subject: "Italian Cuisine", language: "Italian", phrase: "Cucina Italiana" },
    { subject: "Yoga Exercises", language: "Hindi", phrase: "योग प्राणायाम" },
    { subject: "Traditional Medicine", language: "Mandarin", phrase: "传统医学" },
    { subject: "Football Tactics", language: "Portuguese", phrase: "Táticas de Futebol" },
    { subject: "Desert Poetry", language: "Arabic", phrase: "شعر الصحراء" },
    { subject: "Minoan Archaeology", language: "Greek", phrase: "Μινωική αρχαιολογία" },
    { subject: "Ukrainian Embroidery", language: "Ukranian", phrase: "Українська вишивка" },
    { subject: "Druidic Rituals", language: "Welsh", phrase: "seremonïau derwydd" },
    
    { subject: "Amazonian Myths", language: "Spanish", phrase: "mitos amazónicos" },
    { subject: "Learning Taekwondo", language: "Korean", phrase: "태권도 배우기" }, 
    { subject: "Samurai Philosophy", language: "Japanese", phrase: "侍哲学" },
    { subject: "French Wines", language: "France", phrase: "Vins français" },
    { subject: "Alpine Yodeling", language: "German", phrase: "Alpenjodeln" },
    { subject: "Renaissance Sculpture", language: "Italian", phrase: "scultura del Rinascimento" },
    { subject: "Vedic Astrology", language: "Hindi", phrase: "वैदिक ज्योतिष" },
    { subject: "Panda Conservation", language: "Mandarin", phrase: "熊猫保护" },
    { subject: "Ancient Navigation", language: "Portuguese", phrase: "navegação antiga" },
    { subject: "Ottoman Calligraphy", language: "Turkish", phrase: "Osmanlı hat sanatı" },,
    { subject: "Viking Sagas", language: "Swedish", phrase: "Vikingasagor" },
    { subject: "Siberian Folktales", language: "Russian", phrase: "сибирский фольклор" },
    { subject: "Tulip Cultivation", language: "Dutch", phrase: "tulpenkwekerij" },
];

type marqueeIphoneProps = {
    subject: string;
    language: string;
    phrase: string;
    playVideo: (videoId: string) => void;
    rowIdx: number;
}

export function MarqueeIphone(props: marqueeIphoneProps) {
    const {subject, language, phrase} = props;

    const doSomething = () => {
        props.playVideo(subject);
    }

    return (
        <div key={props.rowIdx} className="marqueeIphone">
            <h1>{subject}</h1>
            {/* <FontAwesomeIcon icon={faCirclePlay} className='playIcon'/> */}
            <hr className="customDivider"/>
            <h2>{phrase}</h2>
        </div>
    );
}
