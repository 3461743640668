type WhitespaceDividerProps = {
    height: number;
}

export function WhitespaceDivider(props: WhitespaceDividerProps) {
    return (
        <div className="whiteSpaceDivider" style={{
            backgroundColor: 'white', 
            height: `${props.height}vh`,
            width: '100%'
        }}/>
    );
}
