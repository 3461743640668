import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import "../style/Header.scss";
import { Link } from 'react-router-dom';

export function Header() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    // Function to handle scroll down for button click
    const handleButtonClick = () => {
        window.scrollBy({ top: window.innerHeight, left: 0, behavior: 'smooth' });
    };

    // Function to handle window scroll for changing header style
    const handleWindowScroll = () => {
        const header = document.querySelector('.landingHeader');
        if (!header) return; // If header is not found, do nothing (this should never happen)
        if (window.scrollY > 0) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    };

    // Setup the event listener on component mount and cleanup on unmount
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        window.addEventListener('scroll', handleWindowScroll);
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Mobile View
    if (isMobileView) {
        return (
            <div className='landingHeaderContainerMobile'>
            <div className="landingHeader">
                <div className="leftHeader">
                    <h1> <Link to="/">Slang</Link></h1>
                </div>
                <div className="rightHeader">
                    <h2 className='aboutButton'> <Link to="/about">About</Link> </h2>
                    <Button 
                        className="muibutton" 
                        variant="contained"
                        sx={{
                            width: '35vw', // Example mobile width
                            height: '5vh', // Example mobile height
                            backgroundColor: '#6184D8',
                        }}
                        // onClick={handleButtonClick}
                        href="https://testflight.apple.com/join/CORi2KWL" // Link to the desired URL
                    >
                        Download 
                    </Button>
                </div>
            </div>
        </div>
        );
    }

    return (
        <div className='landingHeaderContainer'>
            <div className="landingHeader">
                <div className="leftHeader">
                    <h1> <Link to="/">Slang</Link></h1>
                </div>
                <div className="rightHeader">
                    <h2 className='aboutButton'> <Link to="/about">About</Link> </h2>
                    <Button 
                        className="muibutton" 
                        variant="contained"
                        sx={{
                            width: '10vw',
                            height: '8vh',
                            backgroundColor: '#6184D8',
                        }}
                        // onClick={handleButtonClick}
                        href="https://testflight.apple.com/join/CORi2KWL" // Link to the desired URL
                    >
                        Download 
                    </Button>
                </div>
            </div>
        </div>
    );
}
