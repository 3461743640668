import { MarqueeScroller } from "./page_2_components/MarqueeScroller";
import "../style/Page2.scss";
import { useState } from "react";
import { VideoPlayer } from "./page_2_components/VideoPlayer";

export function Page2View() {
    const [videoPlaying, setVideoPlaying] = useState(false);

    const playVideo = (videoId: string) => {
        console.log(videoId);
        setVideoPlaying(true);
    }

    const closeVideo = () => {
        setVideoPlaying(false);
    }

    return (
        <div className="page2Container">
            {videoPlaying ? <VideoPlayer videoId="" closeVideo={closeVideo}/> : null}
            <MarqueeScroller playVideo={playVideo}/>
        </div>
    );
}