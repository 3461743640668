import { useEffect, useState } from "react";
import "../style/AboutPage.scss";
import { Page5View } from "./Page5";
import { WhitespaceDivider } from "./WhitespaceDivider";

type SectionProps = {
    title: string;
    content: string;
};

const Section = (props: SectionProps) => (
    <div className="section">
        <h1 className="sectionTitle">{props.title}</h1>
        <h2 className="sectionContent">{props.content}</h2>
    </div>
);

const founder_img = require("../images/founder.png");

const sections = [
    {
        title: "Who are we?",
        content: `Hi! It's Carter and Shravan from Slang, and we're just two nerds who
                love to learn. With our experience working in machine learning at companies
                big and small, and the exciting new possibilities offered by generative AI,
                we thought we could combine what we know and what we love.`
    },
    {
        title: "Why build Slang?",
        content: `We believe generative AI can revolutionize the way we learn!
                Slang is our attempt to use generative AI models in text, audio,
                images to create a comprehensive learning experience that can
                adapt to any user. With Slang, we hope to create an experience where
                every user can learn what they want, how they want.`
    },
];

export function AboutPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={isMobile ? "aboutPageContainerMobile" : "aboutPageContainer"}>
                <img className="GroupPhoto" src={founder_img} alt="GroupPhoto" />
                <div className={isMobile ? "sectionContainerMobile" : "sectionContainer"}>
                    {sections.map((section, index) => (
                        <Section key={index} title={section.title} content={section.content} />
                    ))}
                </div>
            </div>
            <WhitespaceDivider height={25}/>
            <Page5View />
        </>
    );
}