import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Header } from "./components/Header";
import { LandingPage } from "./components/LandingView";
import { Page2View } from "./components/Page2";
import { Page4View } from "./components/Page4";
import { Page5View } from "./components/Page5";
import { WhitespaceDivider } from "./components/WhitespaceDivider";
import {AboutPage} from "./components/AboutPage"; // Import your new page component

export default function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <LandingPage />
              <WhitespaceDivider height={0}/>
              <Page2View />
              <WhitespaceDivider height={25}/>
              <Page4View />
              <WhitespaceDivider height={25}/>
              <Page5View />
            </>
          } />
          <Route path="/about" element={<AboutPage/>} />
        </Routes>
      </div>
    </Router>
  );
}
