import ReactPlayer from "react-player";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

type VideoPlayerProps = {
    videoId: string;
    closeVideo: () => void;
}

export function VideoPlayer(props: VideoPlayerProps) {
    return (
        <div className="videoPlayerOverlay">
            <div className="playerWrapper">
                <ReactPlayer
                    url="https://d2tv9v1885rnl6.cloudfront.net/app_recording.mp4"
                    playing
                    controls
                    width="100%"
                    height="100%"
                />
            </div>

            <FontAwesomeIcon icon={faCircleXmark} onClick={props.closeVideo} className="closeButton"/>
        </div>
    )
}